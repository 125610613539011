<template>
   	<textarea  autocomplete="off" 
			v-bind:class="{isfocus:isFocus}"  v-model="inputValue"
			:placeholder="placeholder"
			:rows="rows"
			:maxlength="maxlength"
			:minlength="minlength"
	></textarea>
</template>

<script>
export default {
	props: {
		value: {
			type: String
		},
		placeholder: {
			default: function() {
				return "";
			},
			type: String
		},
        label: {
			default: function() {
				return "";
			},
			type: String
		},
		clearable: {
			default: false
		},
		disabled: {
			default: false
		},
		type: {
			default: "text"
		},
	},
	data() {
		return {
			inputValue: "",
            label: '',
			isFocus: false
		};
	},
	methods: {
		clear() {
			this.inputValue = "";
			this.$emit("update:value", this.inputValue);
		},
		changes() {
			if (this.disabled == undefined) {
				this.isFocus = !this.isFocus;
			} else {
				if (this.disabled !== false) {
					this.isFocus = false;
				} else {
					this.isFocus = !this.isFocus;
				}
			}
		},
		documentClick(e) {
			try {
				if (this.$refs.input.contains(e.target)) return;
				this.isFocus = false;
			} catch (e) {
				console.log(e);
			}
		}
	},
	watch: {
		inputValue(newVal) {
			this.$emit("update:value", newVal);
		},
		value() {
			if (this.inputValue !== this.value) this.inputValue = this.value;
		}
	},
	created() {
		this.inputValue = this.value;
	},
	mounted() {
		document.addEventListener("click", this.documentClick); 
	},
	beforeDestroy() {
		document.removeEventListener("click", this.documentClick); 
	}
};
</script>



<style lang="less">
    .input-field {
        label {
            display: block;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #1A2744;
            padding-left: 20px;
            margin-bottom: 6px;
        }
        input {
            height: 56px;
            max-width: 480px;
            width: 100%;
            border: 1px solid #FFC900;
            border-radius: 15px;
            outline: none;
            padding-left: 20px;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #1A2744;

            &::placeholder {
                color: #1A2744;
            }
        }
        textarea {
            width: 100%;
            height: 280px;
            border: 1px solid #FFC900;
            border-radius: 15px;
            resize: none;
            outline: none;
            padding: 20px;
        }
    }
</style>