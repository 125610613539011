<template>
    <div class="form-input" :class="{'has-error': error}">
        <span class="label" :class="{required: required}">{{label}}</span>
        <v-date-picker
            :value="value"
            :update-on-input="true"
            :masks="masks"
            locale="uk"
            :dark="true"
            :popover="{ visibility: 'click' }"
        >
            <template v-slot="{ inputEvents, inputValue, togglePopover}">
                <input
                    :name="name"
                    :value="inputValue"
                    :isHovered="false"
                    readonly
                    v-on="inputEvents"
                />
            </template>
        </v-date-picker>
        <span class="error-label" v-if="error">{{error}}</span>
    </div>
</template>

<script>
export default {
    name: "DatepickerComponent",
    props: {
        value: {
            required: false
        },
        required: {
            type: Boolean,
            required: false
        },
        error: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            masks: {
                input: 'YYYY-MM-DD',
            },
        }
    },
    filters: {
        dateFormat(value) {

        }
    }
}
</script>

<style scoped lang="less">
.form-input {
    position: relative;
    span{
        display: block;
        position: relative;
    }
    .label{
        color: @blueColor;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.33;
        position: relative;
        padding-left: 20px;
        margin-bottom: 8px;
        &.required:before {
            position: absolute;
            content: "*";
            left: 10px;
            width: 5px;
            height: 5px;
            color: red;
        }
    }
    .error-label{
        position: absolute;
        top: 100%;
        right: 0;
        color: @yellowColor;
        font-size: 12px;
        text-align: right;
    }
    input{
        height: 50px;
        width: 100%;
        border-radius: 10px;
        padding-left: 20px;
        border: 1px solid #cdd4e0;
        background: #fff url(../../assets/images/calendar-ico.svg) no-repeat center right 20px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: @blueColor;
        transition: border-color .15s linear, box-shadow .15s linear;
        &:focus {
            box-shadow: 0 24px 24px -14px rgba(205, 212, 224, 0.35);
        }
    }
    &.has-error{
        input{
            border-color: @yellowColor;
        }
    }
}
</style>
