<template>
    <div class="form-input">
        <label :class="{required: required}">
            <span>{{label}}</span>
            <textarea
                :name="name"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                :class="{'has-error': error}"
                :disabled="disabled"
                @input="$emit('input', $event.target.value)"
            />
        </label>
        <span class="error-label" v-if="error">{{error}}</span>
    </div>
</template>

<script>
export default {
    name: "InputComponent",
    props: {
        value: {
            required: false
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        required: {
            type: Boolean,
            required: false
        },
        error: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false
        }
    }
}
</script>

<style scoped lang="less">
.form-input{
    position: relative;
    .error-label{
        position: absolute;
        top: 100%;
        right: 0;
        color: @yellowColor;
        font-size: 12px;
        text-align: right;
    }
    label{
        display: block;
        width: 100%;
        position: relative;
        span{
            font-weight: 400;
            font-size: 12px;
            line-height: 1.33;
            color: @blueColor;
            padding-left: 20px;
            margin-bottom: 6px;
            display: block;
        }
        &.required {
            span::before {
                position: absolute;
                content: "*";
                left: 10px;
                width: 5px;
                height: 5px;
                color: red;
            }
        }
    }
    textarea {
        height: 200px;
        width: 100%;
        border-radius: 10px;
        padding: 10px 20px;
        border: 1px solid #cdd4e0;
        outline: none;
        background-color: #fff;
        font: 500 16px/1.5 @defaultFontFamily;
        color: @blueColor;
        transition: border-color .15s linear, box-shadow .15s linear;
        appearance: none;
        resize: none;
        &::placeholder {
            color: @blueColor;
        }
        &.has-error, &:focus{
            border-color: @yellowColor;
        }
        &:focus {
            box-shadow: 0 24px 24px -14px rgba(205, 212, 224, 0.35);
        }
    }
}
</style>
