<template>
    <section class="wrapper activity-page">
        <CommonBlock title="Контроль">
            <div class="img">
                <img src="../assets/images/cabinet-img.svg" alt="cabinet">
            </div>
        </CommonBlock>
        <article class="content custom-scroll">
            <form class="activity-form" @submit.prevent="submitHandler">
                <input type="hidden" name="type" :value="type">
                <div class="form-group" v-if="type !== 1">
                    <InputComponent label="Назва" required name="title" v-model="activity.title" :error="errors.title"/>
                </div>
                <div class="form-group" v-else>
                    <CustomSelectV2
                        label="Канал"
                        name="channel_id"
                        required="required"
                        v-model="activity.channel_id"
                        :error="errors.channel_id"
                        :options='channels'
                    />
                </div>
                <div class="form-group" v-if="type !== 4">
                    <TextareaComponent
                        name="description"
                        v-model="activity.description"
                        required
                        label="Опис"
                        :error="errors.description"
                    />
                </div>
                <div class="form-group">
                    <DatepickerComponent
                        name="activity_date"
                        v-model="activity.activity_date"
                        required
                        label="Дата"
                        :error="errors.activity_date"
                    />
                </div>
                <footer>
                    <CustomButton label="Скасувати" theme="dark" @click.prevent="$router.push({name: 'activity', query: {type: type}})"/>
                    <CustomButton label="Додати запис" v-if="!$route.params.id"/>
                    <CustomButton label="Оновити запис" v-else/>
                </footer>
            </form>
        </article>
    </section>
</template>

<script>
import CommonBlock from "@/components/CommonBlock";
import CustomButton from "@/components/CustomButton";
import ActivityService from "../services/ActivityService";
import InputComponent from "../components/ui/InputComponent";
import TextArea from "../components/ui/TextArea";
import CustomSelectV2 from "../components/ui/CustomSelectV2";
import TextareaComponent from "../components/ui/TextareaComponent";
import DatepickerComponent from "../components/ui/DatepickerComponent";

let clonedActivity = {};
export default {
    name: "ActivityCreate",
    components: {
        TextArea,
        CommonBlock,
        CustomSelectV2,
        TextareaComponent,
        InputComponent,
        CustomButton,
        DatepickerComponent
    },
    data() {
        return {
            type: 1,
            activity_id: null,
            errors: {},
            channels: [
                {
                    label: "Прямий канал",
                    value: 0
                }, {
                    label: "1+1",
                    value: 1
                }, {
                    label: "Інтер",
                    value: 2
                }, {
                    label: "Kyiv live",
                    value: 3
                }, {
                    label: "Kyiv FM",
                    value: 4
                }, {
                    label: "4 канал",
                    value: 5
                }, {
                    label: "П’ятий канал",
                    value: 6
                }, {
                    label: "ICTV",
                    value: 7
                }, {
                    label: "Київ",
                    value: 8
                }, {
                    label: "Еспресо",
                    value: 9
                }, {
                    label: "Україна",
                    value: 10
                }, {
                    label: "СТБ",
                    value: 11
                }, {
                    label: "НАШ",
                    value: 12
                }, {
                    label: "Апостроф",
                    value: 13
                }, {
                    label: "Суспільне",
                    value: 14
                }, {
                    label: "2+2",
                    value: 15
                }, {
                    label: "Друковані та електронні ЗМІ",
                    value: 16
                }, {
                    label: "Регіональні канали",
                    value: 17
                }, {
                    label: "UA: Перший",
                    value: 18
                }
            ],
            activity: {}
        };
    },
    async created() {
        this.type = +this.$route.params.type;
        clonedActivity = {
            title: '',
            description: '',
            activity_date: new Date,
            channel_id: 0
        };

        if(+this.$route.params.id) {
            clonedActivity = await ActivityService.getUserActivity(this.$route.params.id, this.type).catch(this.handleApiError);
            this.activity_id = +this.$route.params.id;
        }
        this.activity = Object.assign({}, clonedActivity);
    },
    methods: {
        cancelSubmit() {
            this.activity = Object.assign({}, clonedActivity);
        },
        async submitHandler(e) {
            let form = new FormData(e.target);

            let resp = {};
            if(+this.$route.params.id) {
                resp = await ActivityService.updateUserActivity(form, this.activity_id).catch(this.handleApiError);
                if(resp) {
                    this.$notify({
                        text: 'Контроль оновленно',
                        type: 'success',
                    });
                    setTimeout(() => {
                        this.$router.push({name: 'activity', query: {type: this.type}});
                    }, 2000);
                }
            }else{
                resp = await ActivityService.createUserActivity(form).catch(this.handleApiError);
                if(resp) {
                    this.$router.push({name: 'activity', query: {type: this.type}});
                }
            }

        },
        handleApiError(err) {
            if(err.data.message) {
                this.$notify({
                    text: err.data.message,
                    duration: 30000,
                    ignoreDuplicates: true,
                    type: 'warn',
                });
            }

            if(err.data) {
                this.errors = err.data;
            }
        }
    }
}
</script>

<style lang="less">
.activity-page{
    .common-block{
        .title{
            margin-top: 60px;
        }
    }
}
</style>

<style scoped lang="less">
.activity-form{
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}
section.wrapper .content{
    padding-top: 76px;
    display: flex;
    align-items: center;
    @media all and(max-width: 950px) {
        padding-top: 40px;
        display: block;
    }
}
.form-group{
    margin-bottom: 20px;
}
.img{
    display: flex;
    height: 100%;
    align-items: center;
    img{
        max-width: 307px;
        width: 100%;
        height: auto;
    }
}
footer{
    text-align: center;
    margin-top: 40px;
    button{
        margin: 0 5px;
    }
    @media all and(max-width: 400px) {
        button{
            display: block;
            margin: 0 auto 20px;
        }
    }
}

</style>
